import React, { useState, useEffect, useMemo } from 'react';
import {
    makeStyles,
    shorthands,
    Table,
    TableHeader,
    TableRow,
    TableHeaderCell,
    TableBody,
    TableCell,
    TableCellLayout,
    Button,
    tokens,
    TableColumnDefinition,
    Spinner,
    Popover,
    PopoverSurface,
    PopoverTrigger,
} from "@fluentui/react-components";
import { ArrowClockwise20Filled, ArrowLeftRegular, ChevronLeftRegular, ChevronRightRegular } from "@fluentui/react-icons";
import { useWorkflow } from '../../libs/hooks/useWorkflow';
import { ExportOutputs } from './ExportOutputs';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100vh - 150px)',
        ...shorthands.overflow('hidden'),
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '20px',
        ...shorthands.padding('0', '20px'),
    },
    content: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.overflow('hidden'),
    },
    tableContainer: {
        flexGrow: 1,
        overflowY: 'auto',
        ...shorthands.padding('0', '20px'),
        '&::-webkit-scrollbar': {
            width: '8px',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: tokens.colorNeutralBackground1,
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: tokens.colorNeutralStroke1,
            ...shorthands.borderRadius('4px'),
        },
        '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: tokens.colorNeutralStroke1Hover,
        },
    },
    table: {
        backgroundColor: tokens.colorNeutralBackground1,
    },
    tableHeader: {
        fontWeight: tokens.fontWeightSemibold,
    },
    paginationContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '20px',
        ...shorthands.padding('20px'),
        ...shorthands.gap('10px'),
    },
    spinnerContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '50%',
    },
    moreButton: {
        marginLeft: '5px',
        minWidth: '30px',
        width: 'auto',
    },
    popoverContent: {
        ...shorthands.padding('10px'),
        maxWidth: '300px',
        wordBreak: 'break-word',
    },
    dateTimeColumn: {
        width: '15%',
    },
    locationColumn: {
        width: '15%',
    },
    companyColumn: {
        width: '15%',
    },
    peopleColumn: {
        width: '15%',
    },
    factColumn: {
        width: '40%',
    },
    headerButtons: {
        display: 'flex',
        ...shorthands.gap('10px'),
    },
});

interface ViewOutputsPageProps {
    workflowInstanceId: string;
    onBack: () => void;
}

interface ChronologyData {
    DateTime: string;
    Location: string[];
    Company: string[];
    People: Array<{ PersonName: string; ActorRole: string }>;
    Fact: string;
}

interface AnswerData {
    Question: string;
    QuestionNumber: number;
    Detail: string;
    Summary: string;
}

type SortDirection = 'ascending' | 'descending';

const formatDate = (dateString: string) => {
    if (!dateString || dateString === "0001-01-01T00:00:00" || new Date(dateString).getTime() === 0) {
        return 'N/A';
    }
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
        return 'N/A';
    }
    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
    };
    return date.toLocaleDateString('en-GB', options);
};

const ITEMS_PER_PAGE = 10;

type WorkflowType = 'Chronology' | 'Answer';

interface WorkflowData {
    payload: string;
    payloadType: WorkflowType;
}

export const ViewOutputsPage: React.FC<ViewOutputsPageProps> = ({ workflowInstanceId, onBack }) => {
    const styles = useStyles();
    const workflowHook = useWorkflow();
    const [outputs, setOutputs] = useState<ChronologyData[] | AnswerData[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortColumn, setSortColumn] = useState<keyof ChronologyData>('DateTime');
    const [sortDirection, setSortDirection] = useState<SortDirection>('descending');
    const [refresh, setRefresh] = useState(false);
    const [workflowType, setWorkflowType] = useState<WorkflowType>('Chronology');

    useEffect(() => {
        const fetchOutputs = async () => {
            try {
                setIsLoading(true);
                const response = await workflowHook.getWorkflowData(workflowInstanceId, "OUTBOUND");
                const data = {
                    ...response,
                    payloadType: response.payloadType.split('.').pop(),
                } as WorkflowData;
                
                const payloadType = data.payloadType as WorkflowType;
                setWorkflowType(payloadType);
                
                if (data.payload) {
                    const parsedPayload = JSON.parse(data.payload) as unknown;
                    if (payloadType === 'Answer') {
                        const typedPayload = parsedPayload as AnswerData[];
                        const sortedAnswers = [...typedPayload].sort((a, b) => a.QuestionNumber - b.QuestionNumber);
                        setOutputs(sortedAnswers);
                    } else {
                        const typedPayload = parsedPayload as ChronologyData[];
                        setOutputs(typedPayload);
                    }
                }
            } catch (err) {
                setError('Failed to fetch outputs');
                console.error('Error fetching outputs:', err);
            } finally {
                setIsLoading(false);
            }
        };

        void fetchOutputs();
    }, [workflowInstanceId, refresh]);

    const sortedOutputs = useMemo(() => {
        if (workflowType === 'Answer') {
            return outputs;
        }

        return [...outputs].sort((a, b) => {
            const aValue = (a as ChronologyData)[sortColumn];
            const bValue = (b as ChronologyData)[sortColumn];

            if (sortColumn === 'DateTime') {
                return sortDirection === 'ascending'
                    ? new Date(aValue as string).getTime() - new Date(bValue as string).getTime()
                    : new Date(bValue as string).getTime() - new Date(aValue as string).getTime();
            }

            if (typeof aValue === 'string' && typeof bValue === 'string') {
                return sortDirection === 'ascending'
                    ? aValue.localeCompare(bValue)
                    : bValue.localeCompare(aValue);
            }

            return 0;
        });
    }, [outputs, sortColumn, sortDirection, workflowType]);

    const totalPages = Math.ceil(sortedOutputs.length / ITEMS_PER_PAGE);
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = Math.min(currentPage * ITEMS_PER_PAGE, sortedOutputs.length);
    const paginatedOutputs = sortedOutputs.slice(startIndex, endIndex);

    const handleSort = (columnId: keyof ChronologyData) => {
        if (columnId === sortColumn) {
            setSortDirection(sortDirection === 'ascending' ? 'descending' : 'ascending');
        } else {
            setSortColumn(columnId);
            setSortDirection('ascending');
        }
    };

    const renderPopoverContent = (items: string[] | Array<{ PersonName: string; ActorRole: string }>) => {
        if (Array.isArray(items) && items.length > 0 && typeof items[0] === 'object') {
            return (items as Array<{ PersonName: string; ActorRole: string }>).map((item, index) => (
                <div key={index}>{`${item.PersonName} (${item.ActorRole})`}</div>
            ));
        }
        return (items as string[]).map((item, index) => <div key={index}>{item}</div>);
    };

    const renderMultipleEntries = (items: string[] | Array<{ PersonName: string; ActorRole: string }>, maxLength: number) => {
        if (items.length <= maxLength) {
            if (typeof items[0] === 'string') {
                return (items as string[]).join(', ');
            } else {
                return (items as Array<{ PersonName: string; ActorRole: string }>)
                    .map(item => `${item.PersonName} (${item.ActorRole})`).join(', ');
            }
        }

        const displayedItems = items.slice(0, maxLength);
        const remainingCount = items.length - maxLength;

        return (
            <>
                {typeof displayedItems[0] === 'string'
                    ? (displayedItems as string[]).join(', ')
                    : (displayedItems as Array<{ PersonName: string; ActorRole: string }>)
                        .map(item => `${item.PersonName} (${item.ActorRole})`).join(', ')}
                <Popover>
                    <PopoverTrigger>
                        <Button appearance={"outline"} size={"small"} className={styles.moreButton}>
                            +{remainingCount}
                        </Button>
                    </PopoverTrigger>
                    <PopoverSurface className={styles.popoverContent}>
                        {renderPopoverContent(items)}
                    </PopoverSurface>
                </Popover>
            </>
        );
    };

    const chronologyColumns: Array<TableColumnDefinition<ChronologyData>> = [
        {
            columnId: 'DateTime',
            compare: (a, b) => new Date(a.DateTime).getTime() - new Date(b.DateTime).getTime(),
            renderHeaderCell: () => (
                <TableHeaderCell
                    onClick={() => { handleSort('DateTime'); }}
                    sortDirection={sortColumn === 'DateTime' ? sortDirection : undefined}
                    className={styles.dateTimeColumn}
                >
                    Date & Time
                </TableHeaderCell>
            ),
            renderCell: (item) => <TableCellLayout>{formatDate(item.DateTime)}</TableCellLayout>,
        },
        {
            columnId: 'Location',
            compare: (a, b) => (a.Location[0] || '').localeCompare(b.Location[0] || ''),
            renderHeaderCell: () => (
                <TableHeaderCell
                    onClick={() => { handleSort('Location'); }}
                    sortDirection={sortColumn === 'Location' ? sortDirection : undefined}
                    className={styles.locationColumn}
                >
                    Location
                </TableHeaderCell>
            ),
            renderCell: (item) => (
                <TableCellLayout>
                    {item.Location.length > 0 ? renderMultipleEntries(item.Location, 2) : 'N/A'}
                </TableCellLayout>
            ),
        },
        {
            columnId: 'Company',
            compare: (a, b) => (a.Company[0] || '').localeCompare(b.Company[0] || ''),
            renderHeaderCell: () => (
                <TableHeaderCell
                    onClick={() => { handleSort('Company'); }}
                    sortDirection={sortColumn === 'Company' ? sortDirection : undefined}
                    className={styles.companyColumn}
                >
                    Company
                </TableHeaderCell>
            ),
            renderCell: (item) => (
                <TableCellLayout>
                    {item.Company.length > 0 ? renderMultipleEntries(item.Company, 2) : 'N/A'}
                </TableCellLayout>
            ),
        },
        {
            columnId: 'People',
            compare: (a, b) => (a.People[0]?.PersonName || '').localeCompare(b.People[0]?.PersonName || ''),
            renderHeaderCell: () => (
                <TableHeaderCell
                    onClick={() => { handleSort('People'); }}
                    sortDirection={sortColumn === 'People' ? sortDirection : undefined}
                    className={styles.peopleColumn}
                >
                    People
                </TableHeaderCell>
            ),
            renderCell: (item) => (
                <TableCellLayout>
                    {item.People.length > 0 ? renderMultipleEntries(item.People, 1) : 'N/A'}
                </TableCellLayout>
            ),
        },
        {
            columnId: 'Fact',
            compare: (a, b) => a.Fact.localeCompare(b.Fact),
            renderHeaderCell: () => (
                <TableHeaderCell
                    onClick={() => { handleSort('Fact'); }}
                    sortDirection={sortColumn === 'Fact' ? sortDirection : undefined}
                    className={styles.factColumn}
                >
                    Fact
                </TableHeaderCell>
            ),
            renderCell: (item) => <TableCellLayout>{item.Fact}</TableCellLayout>,
        },
    ];

    const answerColumns: Array<TableColumnDefinition<AnswerData>> = [
        {
            columnId: 'QuestionNumber',
            compare: (a, b) => a.QuestionNumber - b.QuestionNumber,
            renderHeaderCell: () => (
                <TableHeaderCell>
                    Question #
                </TableHeaderCell>
            ),
            renderCell: (item) => <TableCellLayout>{item.QuestionNumber}</TableCellLayout>,
        },
        {
            columnId: 'Question',
            compare: (a, b) => a.Question.localeCompare(b.Question),
            renderHeaderCell: () => (
                <TableHeaderCell>
                    Question
                </TableHeaderCell>
            ),
            renderCell: (item) => <TableCellLayout>{item.Question}</TableCellLayout>,
        },
        {
            columnId: 'Summary',
            compare: (a, b) => a.Summary.localeCompare(b.Summary),
            renderHeaderCell: () => (
                <TableHeaderCell>
                    Summary
                </TableHeaderCell>
            ),
            renderCell: (item) => <TableCellLayout>{item.Summary}</TableCellLayout>,
        },
        {
            columnId: 'Detail',
            compare: (a, b) => a.Detail.localeCompare(b.Detail),
            renderHeaderCell: () => (
                <TableHeaderCell>
                    Detail
                </TableHeaderCell>
            ),
            renderCell: (item) => <TableCellLayout>{item.Detail}</TableCellLayout>,
        },
    ];

    const handlePrevPage = () => {
        setCurrentPage((prev) => Math.max(prev - 1, 1));
    };

    const handleNextPage = () => {
        setCurrentPage((prev) => Math.min(prev + 1, totalPages));
    };

    const handleRefresh = () => {
        setRefresh(!refresh);
    }

    return (
        <div className={styles.root}>
            <div className={styles.header}>
                <h2>Workflow Outputs</h2>
                <div className={styles.headerButtons}>
                    <ExportOutputs workflowInstanceId={workflowInstanceId} />
                    <Button icon={<ArrowLeftRegular />} onClick={onBack}>Back to Workflow</Button>
                    <Button
                        icon={<ArrowClockwise20Filled />}
                        onClick={handleRefresh}
                    />
                </div>
            </div>
            <div className={styles.content}>
                {isLoading ? (
                    <div className={styles.spinnerContainer}>
                        <Spinner size="large" label="Loading outputs..." />
                    </div>
                ) : error ? (
                    <div>{error}</div>
                ) : (
                    <>
                        <div className={styles.tableContainer}>
                            <Table aria-label="Outputs table" className={styles.table}>
                                <TableHeader>
                                    <TableRow>
                                        {workflowType === 'Chronology' 
                                            ? chronologyColumns.map((column) => (
                                                <React.Fragment key={column.columnId}>
                                                    {column.renderHeaderCell({})}
                                                </React.Fragment>
                                            ))
                                            : answerColumns.map((column) => (
                                                <React.Fragment key={column.columnId}>
                                                    {column.renderHeaderCell({})}
                                                </React.Fragment>
                                            ))
                                        }
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    {paginatedOutputs.map((item, index) => (
                                        <TableRow key={index}>
                                            {workflowType === 'Chronology'
                                                ? chronologyColumns.map((column) => (
                                                    <TableCell key={`${index}-${column.columnId}`}>
                                                        {column.renderCell(item as ChronologyData)}
                                                    </TableCell>
                                                ))
                                                : answerColumns.map((column) => (
                                                    <TableCell key={`${index}-${column.columnId}`}>
                                                        {column.renderCell(item as AnswerData)}
                                                    </TableCell>
                                                ))
                                            }
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </div>
                        <div className={styles.paginationContainer}>
                            <Button
                                icon={<ChevronLeftRegular />}
                                onClick={handlePrevPage}
                                disabled={currentPage === 1}
                            />
                            <span>
                                Showing {startIndex + 1}-{endIndex} of {sortedOutputs.length} outputs | Page {currentPage} of {totalPages}
                            </span>
                            <Button
                                icon={<ChevronRightRegular />}
                                onClick={handleNextPage}
                                disabled={currentPage === totalPages}
                            />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default ViewOutputsPage;
